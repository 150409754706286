import moment from "moment";
import LocalStorageManager from "@/LocalStorageManager";
import routerPath from "@/router/routerPath";
import { stringToDate } from "@/utils/dateUtils";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default class InspectModel {
  constructor() {
    this.objRaw = {};
    this.docId = ""; //계약문서번호
    this.orderCid = ""; //발주문서번호
    this.isRefPk = false;
    this.inspect = ""; //검수담당자
    this.inspectName = ""; //검수담당자한글
    this.purchase = ""; //구매담당자
    this.purchaseName = ""; //구매담당자한글

    this.inspectNum = ""; //정산ID
    this.currencyCode = "KRW";
    this.stateCode = ""; //상태코드
    this.stateCodeName = "";
    this.totalPrice = 0;
    this.balance = 0; // 검수후잔액
    this.contractInfo = {}; //계약 정보
    this.orderInfo = {}; //발주 정보
    this.orderGoodsList = []; //발주내역
    this.inspectItemList = []; //납품내역
    this.requestCid = "-"; // 문서번호
    this.histories = []; //히스토리
    this.description = "";
    this.reason = "";
    this.truncationPrice = "";
    this.regId = "";
    this.degreeSumList = [];
    this.degree = 0;
    this.deleteOrderGoodsList = []; // 납품취소내역
    this.inspectCompleteDate = "";

    this.contentsHistory = ""; // 납품내역
    this.deliveryCompleteDate = ""; // 납품완료일필수항목
    this.inspectEndDate = ""; // 검수완료일필수항목
    this.attachFileList = []; // 검수 증빙 문서

    //this.inspectFile = [];
    this.etc = ""; //의견
    this.partnerItemList = [];
    this.requestTotalPrice = 0;
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);
    const {
      stateCode,
      stateCodeName,
      orderCid,
      currencyCode,
      inspect,
      inspectName,
      purchase,
      purchaseName,
      orderInspectList,
      totalPrice,
      balance,
      orderGoodsList,
      inspectItemList,
      truncationPrice,
      degreeSumList,
      inspectEndDate,
      histories,
      inspectNum,
      degree,
      deleteOrderGoodsList,
      contentsHistory,
      deliveryCompleteDate,
      inspectCompleteDate,
    } = obj;
    this.stateCode = stateCode;
    this.stateCodeName = stateCodeName;
    this.orderCid = orderCid;
    // this.currencyCode = currencyCode;
    this.inspect = inspect; //검수담당자
    this.inspectName = inspectName; //검수담당자한글
    this.purchase = purchase; //구매담당자
    this.purchaseName = purchaseName; //구매담당자한글
    this.histories = orderInspectList;
    this.totalPrice = totalPrice;
    this.inspectEndDate = inspectEndDate;
    this.inspectCompleteDate = inspectCompleteDate;

    this.requestTotalPrice = inspectItemList.reduce((acc, cur) => {
      let ret = acc;
      ret += Number(cur.inspectPrice);
      return Number(Math.round(ret * 10) / 10);
    }, 0);
    if (inspectItemList && inspectItemList.length < 1) {
      this.inspectItemList = orderGoodsList.map((item) => {
        return {
          ...item,
          inspectUnitPrice: item.price,
          price: item.totalPrice,
          deliveryQuantity: String(0),
          inspectAmount: String(0),
        };
      });
    } else {
      this.inspectItemList = inspectItemList.map((item) => {
        return {
          ...item,
          inspectPrice:
            this.stateCode === "INSPECT_REQUEST" ? String(0) : String(item.inspectPrice),
          inspectUnitPrice: String(item.inspectUnitPrice),
          deliveryQuantity: String(item.deliveryQuantity),
          inspectAmount: String(item.inspectAmount),
        };
      });
    }

    this.orderGoodsList = orderGoodsList;
    this.balance = balance;
    this.truncationPrice = truncationPrice;
    this.orderInspectList = orderInspectList;

    this.degreeSumList = degreeSumList;
    this.degree = degree;

    this.deleteOrderGoodsList = deleteOrderGoodsList;

    const orderIspectInfo = _.cloneDeep(orderInspectList).find((item) => {
      return item.regId !== "system";
    });

    if (this.stateCode !== "INSPECT_COMPLETE") {
      this.contentsHistory =
        this.orderInspectList.length > 0 && this.orderInspectList[0].contentsHistory
          ? this.orderInspectList[0].contentsHistory
          : "";

      this.deliveryCompleteDate =
        this.orderInspectList.length > 0 && this.orderInspectList[0].deliveryCompleteDate
          ? moment(this.orderInspectList[0].deliveryCompleteDate, "YYYYMMDD")
          : "";
    } else {
      this.contentsHistory = contentsHistory;
      this.deliveryCompleteDate = moment(deliveryCompleteDate, "YYYYMMDD");
    }

    // if (orderIspectInfo) {
    //   const { contentsHistory, deliveryCompleteDate, attachFileList } = orderIspectInfo;
    //   this.contentsHistory = contentsHistory || '';
    //   this.deliveryCompleteDate = stringToDate(deliveryCompleteDate) || null;
    //   //this.attachFileList = attachFileList || [];
    // }

    // if (this.stateCode == 'INITIAL') {
    //   //this.attachFileList = [];
    //   this.deliveryQuantity = null;
    //   this.contentsHistory = '';
    // }
  }

  setInspectItemListWithDegreeMax(obj) {
    let inspectItemListInfoForView = _.cloneDeep(obj);
    inspectItemListInfoForView.sort((a, b) => {
      return b.degree - a.degree;
    });

    const { degree } = inspectItemListInfoForView[0];
    return inspectItemListInfoForView.filter((item) => {
      return item.degree == degree;
    });
  }

  setInspectItemInfoData(obj) {
    const { orderGoodsList, inspectItemList, stateCode } = obj;
    const degreeListWithDegreeZero = inspectItemList.filter((item) => item.degree === 0).reverse();
    const lengthNum = orderGoodsList.length - 1;

    let degreeListWithDegreeMax =
      inspectItemList.length == 0 ? [] : this.setInspectItemListWithDegreeMax(inspectItemList);

    const orderGoodsInspectList = orderGoodsList.reduce((acc, item, index) => {
      const goodsNum = item.goodsNum;

      // 납품수량 : 담당자가 입력한 납품수량 or 발주수량 or 파트너사가 입력한 납품수량 (degree = 0)
      let deliveryQuantity =
        (degreeListWithDegreeZero.length == 0 && degreeListWithDegreeMax.length > 0) ||
        (degreeListWithDegreeZero.length > 0 && degreeListWithDegreeMax.length > 0)
          ? degreeListWithDegreeMax.find((item) => item.goodsNum === goodsNum).deliveryQuantity
          : degreeListWithDegreeZero.length == 0 && degreeListWithDegreeMax.length == 0
          ? (deliveryQuantity = item.quantity)
          : degreeListWithDegreeZero.find((item) => item.goodsNum === goodsNum).deliveryQuantity;

      // 합격수량 : 담당자가 입력한 합격수량
      let { inspectAmount } =
        degreeListWithDegreeMax.length > 0
          ? degreeListWithDegreeMax.find((item) => item.goodsNum === goodsNum)
          : { inspectAmount: "" };

      // 검수금액 : 담당자가 입력한 검수금액
      let { inspectPrice } =
        degreeListWithDegreeMax.length > 0
          ? degreeListWithDegreeMax.find((item) => item.goodsNum === goodsNum)
          : { inspectPrice: "" };

      // 검수요청전 일 경우, 초기화
      if (stateCode == "INITIAL") {
        deliveryQuantity = "";
        inspectAmount = "";
        inspectPrice = "";
      }

      // 검수요청 일 경우, 합격수량, 검수금액 초기화
      if (stateCode == "INSPECT_REQUEST") {
        deliveryQuantity = degreeListWithDegreeZero[lengthNum - index].deliveryQuantity;
        inspectAmount = "";
        inspectPrice = "";
      }

      acc = [
        ...acc,
        {
          ...item,
          deliveryQuantity: String(deliveryQuantity),
          inspectAmount: String(inspectAmount),
          inspectPrice: String(inspectPrice),
        },
      ];
      return acc;
    }, []);
    this.orderGoodsList = orderGoodsInspectList;
  }

  setOrderInfoData(obj) {
    const {
      goodsDate, // 발주일
      goodsDeadline, // 납품기한
      title, //발주명
      companyName, //파트너사
      inspectCompleteDate, //검수완료일
      deliveryCompleteDate, //검수완료일
      orderGoodsList,
      inspectItemList,
    } = obj;

    this.orderInfo = {
      goodsDate,
      goodsDeadline,
      title,
      companyName,
      inspectCompleteDate,
      deliveryCompleteDate,
    };
  }

  getData() {
    // const updateInspectItemList = this.orderGoodsList;
    // updateInspectItemList.forEach((item) => {
    //   item.inspectPrice = getMoneyToNumber(item.inspectPrice);
    //   item.inspectNum = this.inspectNum;
    //   item.orderCid = this.orderCid;
    //   delete item.totalPrice;
    //   delete item.displayCode;
    //   delete item.price;
    //   delete item.quantity;
    // });
    const updateInspectItemList = this.inspectItemList.map((item) => {
      return {
        orderCid: this.orderCid,
        inspectNum: this.inspectNum,
        deliveryQuantity: item.deliveryQuantity,
        inspectPrice: item.inspectAmount > 0 ? getMoneyToNumber(item.inspectPrice) : 0,
        inspectAmount: item.inspectAmount,
        goodsNum: item.goodsNum,
      };
    });

    const obj = {
      contentsHistory: this.contentsHistory,
      deliveryCompleteDate: moment(this.deliveryCompleteDate).format("YYYYMMDD"),
      orderCid: this.orderCid,
      etc: this.etc,
      description: this.description,
      inspectEndDate: this.inspectEndDate ? moment(this.inspectEndDate).format("YYYYMMDD") : null,
      attachFileList: this.attachFileList,
      inspectYn: !this.inspectYn,
      reason: this.reason,
      inspectNum: this.inspectNum,
      updateInspectItemList: updateInspectItemList || [],
    };

    return Object.assign({}, obj);
  }
  getPostData() {
    const obj = {
      orderCid: this.orderCid,
    };

    return Object.assign({}, obj);
  }
  getAlertMessage() {
    if (!this.contentsHistory) return "검수내역을 입력해주세요.";
    if (!this.deliveryCompleteDate) return "납품완료일을 선택해주세요.";
    // if (!this.inspectEndDate) return "검수완료일을 선택해주세요.";
    if (this.stateCode === "INITIAL") {
      if (this.attachFileList.length < 1) return "검수 증빙 문서을 첨부해주세요.";
    }

    return null;
  }

  getCountMessage() {
    if (this.reason) return "발주수량보다 ";
    return null;
  }

  getRejectAlertMessage() {
    if (!this.reason) return "반려사유를 입력해주세요";

    return null;
  }

  getDeliveryQuantity() {
    if (this.stateCode === "INSPECT_REQUEST") {
      //inspectItemList.;
    }
  }
}
