<!--
[
  { code: '', name: '전체', desc: '전체', index: 0 },
  { code: 'INITIAL', name: '검수 요청 전', desc: '검수 요청 전', index: 1 },
  { code: 'INSPECT_REQUEST', name: '검수 요청', desc: '검수 요청', index: 2 },
  { code: 'INSPECT_APPROVE', name: '검수 승인', desc: '검수 승인', index: 3 },
  { code: 'INSPECT_REJECT', name: '검수 반려', desc: '검수 반려', index: 4 },
  { code: 'PURCHASE_APPROVE', name: '구매 검수 승인', desc: '구매 검수 승인', index: 5 },
  { code: 'PURCHASE_REJECT', name: '구매 검수 반려', desc: '구매 검수 반려', index: 6 },
  { code: 'INSPECT_COMPLETE', name: '검수 완료', desc: '검수 완료', index: 7 },
  {
    code: 'INSPECT_REQUEST_CANCEL',
    name: '검수 요청 취소',
    desc: '검수 요청 취소',
    index: 8,
  },
  { code: 'INSPECT_DELETE', name: '검수 취소', desc: '검수 취소', index: 9 },
];
-->
<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">
          <template v-if="model.stateCode !== 'INSPECT_DELETE'">
            {{ model.stateCode === "INSPECT_COMPLETE" ? "검수 완료" : "검수중" }}
          </template>
          <template v-else>
            {{ "검수 취소" }}
          </template>
          <span class="txt_view">{{ model.inspectNum }}</span>
        </h2>
      </div>
      <div class="body_section">
        <template>
          <!-- 발주정보 v-if="model.orderCid" -->
          <InspectOrderInfo :orderCid="model.orderCid" :orderInfo="model.orderInfo" />
        </template>
        <!-- 발주 총 금액, 검수 후 잔액 -->
        <TableViewWithTitle title="발주 금액 및 검수 잔액">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <th>발주 총 금액</th>
            <td class="align_right">
              {{ model.totalPrice | currency }}
              <!-- {{ model.assetTypeName }} -->
            </td>
            <th>검수 후 잔액</th>
            <td class="align_right">
              {{ model.balance | currency }}
              <!-- {{ model.assetTypeName }} -->
            </td>
          </template>
        </TableViewWithTitle>
        <template v-if="model.stateCode !== 'INSPECT_DELETE'">
          <InspectItemInfo
            v-if="model.stateCode !== 'INITIAL'"
            ref="InspectItemInfo"
            :degree="model.degree"
            :orderGoodsRequests="model.inspectItemList"
          />
        </template>
        <template v-else>
          <InspectCancelItemInfo :dataList="model.deleteOrderGoodsList" />
        </template>
        <div class="area_view">
          <TableViewWithTitle title="검수 요청 정보">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr>
                <th>검수상태</th>
                <td>
                  {{ model.stateCodeName }}
                </td>
              </tr>
              <tr>
                <th>검수내역</th>
                <td>
                  <!-- <Input :maxLength="20" :value.sync="model.contentsHistory" /> -->
                  {{ model.contentsHistory }}
                </td>
              </tr>
              <tr>
                <th>납품완료일</th>
                <td>
                  <!-- <DatePicker :date.sync="model.deliveryCompleteDate" /> -->
                  {{ stringDeliveryCompleteDate | dateStringFormat }}
                </td>
              </tr>
              <tr>
                <th>검수완료일</th>
                <td>
                  {{ model.inspectCompleteDate | dateStringFormat }}
                </td>
              </tr>
              <!-- <tr>
                <th>검수 증빙 문서</th>
                <td>
                  <FileView :dataList="model.attachFileList ? model.attachFileList : []" />
                </td>
              </tr> -->
            </template>
          </TableViewWithTitle>
        </div>
        <InspectPriceHistory v-if="model.stateCode !== 'INITIAL'" :dataList="model.degreeSumList" />
        <InspectHistory :dataList="model.histories" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <!--@click="onClickPopCancel"-->
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <template v-if="model.stateCode === 'INSPECT_COMPLETE'">
          <!-- <button type="button" class="btn_fourthly btn_large" @click="onClickInspectPrint">
            검수확인서
          </button> -->
        </template>
        <template
          v-if="
            (model.stateCode === 'INSPECT_APPROVE' || model.stateCode === 'INSPECT_COMPLETE') &&
            $isAdmin
          "
        >
          <button type="button" class="btn_fifthly btn_large" @click="onClickReject">반려</button>
        </template>
        <template v-if="model.stateCode === 'INSPECT_APPROVE' && $isAdmin">
          <button type="button" class="btn_primary btn_large" @click="onClickApprove">
            검수승인
          </button>
        </template>
        <!-- 
          신규등록할떄 쓴다
          <button type="button" class="btn_primary btn_large" @click="onClickSave">검수</button> -->
      </div>
    </Sticky>
    <template v-slot:popup>
      <!--isPopInspectPrint-->
      <InspectPrintPopup
        v-if="isPopInspectPrint"
        :model="model"
        @onClickClose="onClickCloseInspectPrint"
      />

      <!-- <AlertPopup
        v-if="isRejectConfirm"
        alertText="검수 반려 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="rejectInspect"
      /> -->

      <InspectRejectPopup
        v-if="isRejectConfirm"
        :model.sync="model"
        @onClickClose="onClickCloseAlertComfirm"
        @onClickSubmit="rejectInspect"
      />
      <AlertPopup
        v-if="isApproveConfirm"
        :alertText="
          model.stateCode !== 'INITIAL' ? '검수 승인 하시겠습니까?' : '검수 요청 하시겠습니까?'
        "
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="approveInspect"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";

import FileWrite from "@/components/common/file/FileWrite.vue";
import FileView from "@/components/common/file/FileView.vue";
import DatePicker from "@/components/common/calendar/DatePicker.vue";
import Sticky from "@/components/layout/content/Sticky";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import InspectContractInfo from "@/components/admin/inspect/write/InspectContractInfo";
import InspectOrderInfo from "@/components/admin/inspect/write/InspectOrderInfo";
import InspectOrderGoodsList from "@/components/admin/inspect/write/InspectOrderGoodsList";
import InspectPriceHistory from "@/components/admin/inspect/write/InspectPriceHistory";
import InspectHistory from "@/components/admin/inspect/write/InspectHistory";
import InspectItemInfo from "@/components/admin/inspect/view/InspectItemInfo";
import InspectCancelItemInfo from "@/components/admin/inspect/view/InspectCancelItemInfo";

import InspectPrintPopup from "@/components/admin/inspect/popup/InspectPrintPopup";
import InspectRejectPopup from "@/components/admin/inspect/popup/InspectRejectPopup";
import { getDateString, dateToString, stringToDate, dateToyymmdd } from "@/utils/dateUtils";
import { addCommaForMoney } from "@/utils/stringUtils";
import { getCountWithCurrency } from "@/utils/spUtils";
import {
  INITIAL,
  REQUESTER_INSPECT,
  REQUESTER_REJECT,
  PURCHASE_INSPECT,
  PURCHASE_CONFIRM,
  INSPECT_REQUEST,
} from "@/constants/inspectStatusList";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import PageMixin from "@/mixins/PageMixin";

import InspectModel from "./InspectModel.js";

export default {
  name: "InspectView",
  components: {
    PageWithLayout,
    TableHead,
    Sticky,
    //Popup,
    TableViewWithTitle,
    //TableView,
    FileWrite,
    FileView,
    Input,
    DatePicker,
    AlertPopup,

    InspectContractInfo,
    InspectOrderInfo,
    InspectOrderGoodsList,
    InspectHistory,
    InspectPrintPopup,
    InspectRejectPopup,
    InspectItemInfo,
    InspectCancelItemInfo,
    InspectPriceHistory,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new InspectModel(),
      isSaveConfirm: false,
      isApproveConfirm: false,
      isRejectConfirm: false,
      isPopInspectPrint: false,
      isRefPk: false,
      inspectNum: "",
      inspectData: {},
      //historieData: {},
      etc: "",
      deliveryHistory: "",
      deliveryCompleteDate: null,
      inspectCompleteDate: null,
      inspectFileList: [],
      isDateCompareError: false,
      compareDateErrorText: "",
      // 2차에 적용에정
      // partnerInspectCompleteDate: null,
    };
  },
  computed: {
    getdegree() {
      let arr = _.cloneDeep(this.model.degreeSumList);
      return arr[arr.length - 1].degree;
    },

    stringDeliveryCompleteDate() {
      return this.moment(this.model.deliveryCompleteDate).format("YYYYMMDD");
    },
    stringInspectEndDate() {
      return dateToString(this.model.inspectEndDate);
    },
  },
  created() {
    const { refPk } = this.$route.params;
    this.inspectNum = refPk;
    this.model.inspectNum = refPk;

    this.initData();
  },
  mounted() {
    this.setInspectEndDateNow();
  },

  methods: {
    async initData() {
      const { refPk } = this.$route.params;
      const path = `${this.$apiPath.ST_INSPECT}/history/${refPk}`;

      const result = await ApiService.shared.get(path);
      if (result.code !== "200") {
        return;
      }

      this.model.setData(result.data);
      this.model.setOrderInfoData(result.data);
      this.model.setInspectItemInfoData(result.data);
    },
    // onClickSave() {
    //   this.isSaveConfirm = true;
    // },
    onClickApprove() {
      this.isApproveConfirm = true;
    },
    onClickReject() {
      this.isRejectConfirm = true;
    },
    onClickInspectPrint() {
      this.isPopInspectPrint = true;
    },
    onClickCloseInspectPrint() {
      this.isPopInspectPrint = false;
    },
    onClickGoToList() {
      this.goToList();
    },
    goToList() {
      this.$router.push({
        path: this.$routerPath.INSPECT_LIST,
        query: this.$route.query,
      });
    },

    //검수승인
    async approveInspect() {
      const { inspectNum } = this.model;
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.onClickCloseAlertComfirm();
        this.alert(alertMessage);
        return;
      }
      const obj = this.model.getData();

      const path = `${this.$apiPath.ST_INSPECT}/approve/${inspectNum}`;
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }
      this.resetInfo(obj);

      this.inspectComplete(data);
    },
    async inspectComplete(inspectData) {
      const { inspectNum } = this.model;
      const docID = inspectNum;
      const { stateCode } = inspectData;
      const stateObj = {
        stateCode,
      };
      const path = `${this.$apiPath.ST_INSPECT}/complete/${docID}`;
      const result = await ApiService.shared.putData(path, stateObj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }

      this.goToList();
    },
    //검수반려
    async rejectInspect() {
      const { inspectNum } = this.model;
      const alertMessage = this.model.getRejectAlertMessage();
      if (alertMessage) {
        this.onClickCloseAlertComfirm();
        this.alert(alertMessage);
        return;
      }
      const obj = this.model.getData();

      // this.addInspectItemList(obj);

      // obj.updateInspectItemList.forEach((item) => {
      //   item.inspectNum = this.model.inspectNum;
      //   item.orderCid = this.model.orderCid;
      // });
      // obj.attachFileList = [];
      this.resetInfo(obj);
      if (this.model.stateCode === "INSPECT_COMPLETE") {
        this.rejectComplete(obj);
        return;
      }

      const path = `${this.$apiPath.ST_INSPECT}/reject/${inspectNum}`;
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }

      this.rejectComplete(obj);
    },
    async rejectComplete(obj) {
      const { inspectNum } = this.model;

      const path = `${this.$apiPath.ST_INSPECT}/complete/${inspectNum}`;

      const result = await ApiService.shared.putData(path, obj);
      const { code, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }

      this.goToList();
    },

    resetInfo(obj) {
      obj.attachFileList = [];
      obj.contentsHistory = "";
      obj.deliveryCompleteDate = null;
      obj.etc = "";
    },

    addInspectItemList(obj) {
      obj.updateInspectItemList.forEach((item) => {
        item.inspectNum = this.model.inspectNum;
        item.orderCid = this.model.orderCid;
      });
    },

    onClickCloseAlertComfirm() {
      this.isSaveConfirm = false;
      this.isApproveConfirm = false;
      this.isRejectConfirm = false;
    },

    inspectFileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    inspectFileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },

    setInspectEndDateNow() {
      //this.model.inspectEndDate = new Date(this.moment(new Date()).set({ hour: 0, minute: 0 }));
    },
  },
};
</script>
